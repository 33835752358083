
input[type="file"] {
  display: none;
}


.custom-file-upload {
  border: 1px solid var(--white);
  padding: 0.1em 0.2em;
  cursor: pointer;
  border-radius: 0.2em;
}

.custom-file-upload:hover {
  color: var(--alt);
  border: 1px solid var(--alt);
  transition: 0.4s;
}

.upload-prof-img { margin-right: 1em; }

.upload-success img { margin-right: 0.5em;}