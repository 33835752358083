

#drag-reorder {
  font-weight: 600;
  color: var(--alt);
}

.shortlist-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em 0;
}

.remove {
  margin: 0;
  margin-left: 0.5em;
  font-weight: 500;
}