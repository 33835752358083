

#auth {
  margin-bottom: 1em;
}

#auth p {
  margin: 0;
}

#spotify-auth {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 0.5em;
}
