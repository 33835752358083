#simulation-helper {
  display: block;
  position: absolute;
  bottom: 10em;
  left: 0;
}

#leaderboard-simulation-helper {
  display: block;
  position: absolute;
  bottom: 15em;
  left: 0;
}