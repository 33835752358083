

.pastYearShortlistHead {
  cursor: pointer;
  margin: 0.5em 0;
}

.pastYearShortlistHead h4 {
  font-size: 1em;
  margin: 0;
  font-weight: 500;
  margin-right: 0.5em;
}


#pastYears {
  padding-top: 2em;
}
