

#toggle-selector {
  width: 100%;
  border: 1px solid var(--black);
  height: 2em;
  display: flex;
  align-items: center;
  border-radius: 0.4em;
  margin-bottom: 2em;
}

.spotify-toggle {
  width: calc(100% / 3);
}

.archive-toggle {
  width: calc(100% / 2);
}

.toggle-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--fade);
  height: 100%;
  border-right: 1px solid black;
  cursor: pointer;
}

.toggle-selector:first-child {
  border-radius: 0.4em 0 0 0.4em;
}

.toggle-selector:last-child {
  border-right: 0 solid black;
  border-radius: 0 0.4em 0.4em 0;
}


.toggle-selected {
  background-color: var(--alt);
}