

.header {
  margin-top: 1.8em;
}

.header h1, .header p {
  margin: 0.2em 0;
}



.error-header {
  background-color: var(--deny);
  padding: 0.5em;
  border-radius: 0.4em;
  border: 1px solid black;
}

.error-header h1, .error-header p {
  margin: 0.2em 0;
}

.error-header h1 {
  margin-left: 0.3em;
}

.error-icon {
  width: 1.2em;
  height: 1.2em;
  object-fit: contain;
}

.close-error {
  width: 1em;
  height: 1em;
  object-fit: contain;
  cursor: pointer;
}

.error-title {
  justify-content: space-between;
}

