
.hamburger-react {
  margin-right: -14px;
}

#active-menu-item {
  margin-top: 10px;
  margin-right: 5px;
}

#active-menu-content {
  font-weight: 600;
  margin-bottom: 10px;
}

#active-menu-item-image {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.stroke-underline {
  height: 3px;
  width: 100%;
  background-color: var(--alt);
}

.pageTitle {
  margin: 0;
}
