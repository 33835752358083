

.modal-background {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal {
  position: fixed;
  top: 30vh;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: var(--fade);
  padding: 1em;
  width: calc(100vw - 2em - 42px);
  margin-left: 20px;
  border-radius: 0.2em;
  border: 1px solid black;
}

.modal p {
  margin: 0;
  margin-bottom: 0.5em;
}

.modal-image {
  height: 4em;
  width: 4em;
  object-fit: contain;
  margin-right: 1em;
}

.confirm-button, .exit-button {
  color: var(--white);
  border: 1px solid black;
  border-radius: 0.2em;
  font-family: 'Montserrat', sans-serif;    
  font-weight: 600;
  font-size: 0.9em;
  width: 6em;
  margin-right: 1em;
}

.confirm-button {
  background-color: var(--accept);
}

.exit-button {
  background-color: var(--exit);
}