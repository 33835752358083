
#linked-header h2 {
  font-size: 1em;
  margin: 0 0.5em;
  font-weight: 500;
}



.linked-action {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 7em;
  border: 1px solid black;
  justify-content: center;
  border-radius: 0.2em;
  margin: 0.5em 1em 0.5em 0;
  background-color: var(--alt);
}

.linked-action-generate {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  border: 1px solid black;
  justify-content: center;
  border-radius: 0.2em;
  margin: 0.5em 1em 0.5em 0;
  width: fit-content;
  background-color: var(--alt);
  padding: 0.2em;
}

.linked-action-generate img {
  width: 1.4em;
  height: 1.4em;
}

#linked-action {
  width: fit-content;
  height: fit-content;
  margin: 0;
  padding: 0.2em 0.5em;
  background-color: var(--alt);
  color: var(--white);
  border: 1px solid black;
  border-radius: 0.2em;
  font-family: 'Montserrat', sans-serif;    
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
  margin-right: 1em;
}

.icon-arrow {
  width: 12px;
  height: 12px;
  margin-left: 0.5em;
}

.icon-playlist {
  width: 20px;
  height: 20px;
  margin-left: 0.5em;
}

.linked-action-list {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.linked-action-list p {
  margin: 0.5em 0;
  margin-right: 1em;
}

.linked-action p {
  margin: 0;
}

.linked-icon {
  width: 1em;
  height: 1em;
  object-fit: contain;
}

/* Playlist section */
#playlist-section {
  margin-bottom: 1em;
}

#playlist-thumb {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 1em;
}

#playlist-title {
  font-size: 1em;
  width: 70vw;
  margin: 0;
}

#playlist-header p {
  margin: 0.3em 0;
  margin-right: 0.5em;
}