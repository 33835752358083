
#search-bar {
  margin: 1.5em 0 1em 0;
}

#search-bar label {
  margin-right: 0.5em;
  font-size: 1.2em;
}

#search-results {
  width: calc(100% - 1.2em - 2px);
  padding: 0.2em 0.6em;
  background-color: var(--fade);
  border-radius: 0.4em;
  border: 1px solid var(--black);
}

#results-header {
  justify-content: space-between;
  font-weight: 700;
}


#results-header p { margin: 0.5em 0.2em; }

.cross {
  width: 0.9em;
  height: 0.9em;
  object-fit: contain;
  margin-left: 0.2em;
  cursor: pointer;
}

.search-result {
  justify-content: space-between;
  margin: 1em 0;
}

.search-actions { width: 6em; }

.search-actions button {
  margin-top: 0.3em;
  width: 100%;
}

.eligibility img {
  width: 1em;
  height: 1em;
  object-fit: contain;
  margin-right: 0.3em;
}

.eligibility p {
  margin: 0;
}

.search-left { width: calc(100% - 6em); }

.search-info {
  margin-left: 0.5em;
  max-width: calc(100% - 4.5em);
}

.search-info p { margin: 0.2em 0.2em; }

.search-actions .add-to-shortlist { margin-bottom: 0.5em; }