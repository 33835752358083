


#name-field p {
  margin: 0;
  padding-right: 0.5em;
}

.player-pick {
  font-size: 0.75em;
  padding: 1em 0;
}

.player-pick-info {
  padding-left: 1em;
}

.player-pick-actions { justify-content: space-between; }


.top-5-player { padding: 0.5em 0; }

#add-player { cursor: pointer; }

.top-5-player p { margin: 0; }

.top-5-player-info { 
  padding-left: 0.5em; 
  justify-content: space-around;
}


.player-eligibility {
  padding-right: 0.2em;
  width: 1em;
  height: 1em;
  object-fit: contain;
}

.edit-picks-button {
  width: 7em;
  cursor: pointer;
}

.eligibility-warning img { padding-top: 0.2em; }

.eligibility-warning p {
  margin: 0;
  padding-left: 0.5em;
}

.update-info-button {    
  background-color: var(--accept);
  color: var(--white);
  border: 1px solid black;
  border-radius: 0.2em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 0.9em;
  width: 8em;
  margin-top: 1em;
  cursor: pointer;
}

.remove-player {
  padding-left: 5em;
}