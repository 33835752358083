#feedback-form {
  font-size: 0.8em;
}

.custom-file-upload-feedback {
  border: 1px solid var(--alt);
  padding: 0.1em 0.2em;
  cursor: pointer;
  border-radius: 0.2em;
  color: var(--white);
}

.feedback-error { font-size: 0.8em; }

.feedback-img-holder {
  width: 3em;
  height: 3em;
  padding: 0.5em;
}

.feedback-img {
  width: 3em;
  height: 3em;
  object-fit: cover;
}

.rmv-feedback-img {
  width: 0.5em;
  height: 0.5em;
  position: absolute;
  margin-left: -0.5em;
  cursor: pointer;
}

#fbk-img-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.send-feedback {
  padding: 0.4em 0.8em;
}

#multi-img { margin: 1em 0 2em 0 }