

.top-menu-item-image {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.top-menu-item-name {
  font-size: 1em;
  font-weight: 700;
  margin: 0.4em 0 0.5em 0;
}

#topMenu {
  margin-top: 1.4em;
  justify-content: space-around;
}