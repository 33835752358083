.companion-rule {
  background-color: var(--fade);
  padding: 0.5em;
  border-radius: 0.2em;
  margin-bottom: 1em;
}

.companion-rule img {
  margin-right: 0.5em;
}


.companion-top {
  justify-content: space-between;
}


.companion-info a { margin-right: 1.5em; }

.preview-info {
  /* max-width: 17em; */
}

#companions {
  flex-wrap: wrap;
  justify-content: space-between;
}