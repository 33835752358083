


#menubar {
  justify-content: space-between;
}


#home {
  font-weight: 700;
}