

.alert {
  width: calc(100vw - 2em - 40px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1em;
  position: fixed;
  bottom: 2em;
  left: 0;
  margin-left: 20px;
  border: 1px solid black;
  border-radius: 0.2em;
  transition: 0.3s;
}

.alert-info p {
  margin: 0.2em 0.5em;
}

.alert-img {
  width: 3em;
  height: 3em;
  object-fit: contain;
}

.alert-icon {
  width: 1.5em;
  height: 1.5em;
  object-fit: contain;
  margin-left: 0.5em;
}

.alert-text {
  font-style: italic;
}