
#slidingMenu {
  width: 100vw;
  height: calc(100vh - 3em);
  position: fixed;
  top: 4em;
  left: 0;
  transition: transform .3s;
  z-index: 1000;
  background-color: var(--background);
  display: flex;
}

#slidingMenuItems {
  width: 100%;
  align-items: flex-end;
  margin-right: 20px;
}

.sliding-menu-selected {
  height: 1.2em;
  width: 0.2em;
  background-color: var(--alt);
  margin-right: 0.3em;
}

.sliding-menu-item-image {
  width: 2em;
  height: 2em;
  object-fit: contain;
  margin: 0 0 0 1.5em;
}

.sliding-menu-item-name {
  font-size: 1.2em;
  font-weight: 700;
  margin: 0;
}

.slidingMenuItem {
  justify-content: right;
  margin: 0.7em 0;
  cursor: pointer;
}

.slide-visible {
  transform: translate3d(0vw, 0, 0);
  overflow: hidden;
}

.slide-hidden {
  transform: translate3d(100vw, 0, 0);
}

#slidingMenu {
  top: 3em;
}

@media only screen and (min-width: 900px) {

  #slidingMenu {
    top: 3em;
  }
}