

.add-to-shortlist {
  align-items: flex-end;
}

.add-s {
  width: 1.3em;
  height: 1.3em;
  object-fit: contain;
  margin-right: 0.3em;
}

.add-to-shortlist p {
  margin: 0;
  font-weight: 500;
}