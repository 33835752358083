
#time-switcher {
  margin: 2em 0;
}

#time-switcher label {
  font-weight: 500;
  font-size: 1.05em;
}

@media only screen and (max-width: 450px) {

  #time-switcher {
    font-size: 0.9em;
  }

  #time-switcher select {
    font-size: 0.9em;
  }
}


.popular-artist {
  font-weight: 700;
  width: calc(100% - 4em);
}

.popular-artist-a {
  font-weight: 700;
  width: calc(100% - 4em);
  margin: 0.2em 0;
}

.popular-track-info {
  justify-content: space-between;
  width: calc(100% - 4em);
  margin-left: 1em;
}

.select-label {
  margin: 0 0.5em;
}

.played {
  margin: 0.2em 0.5em 0 0;
}

.played-info p {
  margin: 0.3em 0 0 0;
}

.add-shortlist-holder {
  align-items: flex-end;
}

.ranking-title-popular {
  max-width:calc(100% - 1.4em);
  align-items: flex-start;
}

