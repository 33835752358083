

.auth-warning {
  padding: 0.5em 1em;
  background-color: var(--fade);
  border-radius: 0.2em;
  border: 1px solid black;
  margin-bottom: 1.5em;
}

.auth-warning h1 {
  margin: 0.2em 0;
}

.auth-warning p {
  margin: 0.5em 0;
}

.close-auth-warning {
  width: 1.2em;
  height: 1.2em;
  object-fit: contain;
}

.auth-warning-header {
  justify-content: space-between;
}

.popup-companion {
  margin-right: 1.5em;
}

#companion-popup { margin-top: 1em; }