@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --background: #191414;
  --alt: #FF8A00;
  --fade: #2c2828;
  --black: #000;
  --white: #FFF;
  --accept: #1b5616;
  --deny: #7a1818;
  --exit : #DB2B2B;
  --error: #f12525;
  --valid: #39D22C;
  --warning: #81711e;
  --info: #004d63;

  background-color: var(--background);
  color: var(--white);
  font-size: 16px;
  padding: 0px;
  margin: 0px;
  font-family: 'Montserrat', sans-serif;

}

.accept { background-color: var(--accept); }
.deny { background-color: var(--deny); }
.valid { color: var(--valid) }
.error { color: var(--error) }

.light-text {
  font-weight: 200;
}

#content {
  width: calc(100% - 40px);
  margin: 0 20px;
  padding-bottom: 4em;
}

.row-centre {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fit { width: fit-content; }

a {
  text-decoration: none;
  color: var(--white);
}

.tac { text-align: center; }

h1 {
  font-size: 1.25em;
}

.hidden {
  opacity: 0;
}

body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow:hidden;
  white-space:nowrap;
}

.btn {
  background-color: var(--alt);
  color: var(--white);
  border: 1px solid black;
  border-radius: 0.2em;
  font-family: 'Montserrat', sans-serif;    
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
}

select {
  background-color: var(--white);
  border-radius: 0.2em;
  color: var(--black);
  font-family: 'Montserrat', sans-serif;    
  font-weight: 700;
  font-size: 1em;
}


.ranking {
  font-weight: 700;
  color: var(--alt);
  height: 1em;
  margin: 0 0.2em 0 0;
}

.bold {
  font-weight: 700;
}

.default {
  font-weight: 300;
}

.sub-head {
  font-size: 1em;
  margin: 1em 0;
}

.thumb-med {
  width: 4.4em;
  height: 4.4em;
  object-fit: cover;
}

.thumb-small {
  width: 2.8em;
  height: 2.8em;
  object-fit: cover;
}

.thumb-mini {
  width: 1.5em;
  height: 1.5em;
  object-fit: cover;
}

.space {
  margin: 0 0.2em;
}

.light-italic {
  font-style: italic;
  font-weight: 300;
}

.no-margin {
  margin: 0
}

.show {
  opacity: 1;
}

.hide {
  opacity: 0;
}

.display-show {
  display: block;
}

.display-show-flex {
  display: flex;
}

.display-hide {
  display: none;
}

.header-subtitle {
  white-space: pre-wrap;
}

input[type=text] {
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
}

button { cursor: pointer; }

textarea {
  font-size: 1em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  resize: none;
}

.form-textarea, .form-text-input {
  margin: 0.5em 0;
  width: -webkit-fill-available;
}

.form-textarea { height: 7ch; }

.form-label {
  display: inline-block;
  width: 8em;
  margin-right: 1em;
}

.no-margin { margin: 0; }
.margin-top { margin: 0.5em 0 0 0; }
.nolink-menu { padding-bottom: 1em; }
.fr { display: flex; flex-direction: row; }
.fc { display: flex; flex-direction: column; }
.ac { align-items: center; }
.jc { justify-content: center; }
.point { cursor: pointer }

.pad-right { margin-right: 0.5em; }
.pad-left { margin-left: 0.5em; }
.m-top { padding-top: 1em; }
.m-bot { padding-bottom: 1em; }

.message-box {
  padding: 0.5em 1em;
  border-radius: 0.2em;
  border: 1px solid black;
  margin-bottom: 1.5em;
  background-color: var(--info);
}

.b-info { background-color: var(--info); }
.b-accept { background-color: var(--accept); }


@media only screen and (max-width: 400px) {
  body {
    font-size: 0.9em;
  }
}


@media only screen and (min-width: 600px) {

  #content, .modal, .alert, #now-playing {
    width: calc(100% - 200px);
    margin: 0 100px;
  }

  #slidingMenuItems {
    margin-right: 100px;
  }
}

@media only screen and (min-width: 800px) {
  #content, .modal, .alert, #now-playing {
    width: calc(100% - 400px);
    margin: 0 200px;
  }

  #slidingMenuItems {
    margin-right: 200px;
    margin-top: 1em;
  }
}

@media only screen and (min-width: 900px) {

  #content {
    font-size: 1.2rem;
  }

  #content, .modal, .alert, #now-playing {
    width: calc(100% - 500px);
    margin: 0 250px;
  }

  #slidingMenuItems {
    margin-right: 250px;
  }

}

@media only screen and (min-width: 700px) {
  .mobile-only {display: none !important; }
}

@media only screen and (max-width: 700px) {
  .desktop-section { display: none; }
  .mobile-selected { display: block !important; }
}