

.warning-title h1 {
  margin: 0;
  margin-right: 0.5em;
}

.warning-message {
  margin: 0.5em 0;
}

.warning-div {
  padding: 1em;
  border: 1px solid black;
  border-radius: 0.5em;
  background-color: var(--accept);
}