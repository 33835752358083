
#section-breaker {
  display: flex;
  justify-content: center;
  margin: 1em 0;
}

#section-breaker img {
  width: 8em;
}

.contact-panel {
  justify-content: flex-start;
}

.contact-panel img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 1em;
}

.contact-panel p {
  font-weight: 500;
}