

#bingo-board {
  flex-wrap: wrap;
  padding: 1.5em 0;
  margin: auto;
}

.bingo-grid {
  height: 5em;
  background-color: var(--white);
  justify-content: center;
  padding: 0.2em;
  border: 1px solid black;
  background-size: cover;
  display: flex;
  align-items: center;
  width: calc(100%/4 - 1.2em);
  cursor: pointer;
}

.bingo-grid p {
  color: var(--black)
}

.leaderboad-stat p { 
  margin: 0.2em 0;
  margin-right: 0.2em;
}

.bingo-board-add-bg {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.bingo-board-add {
  width: calc(90% - 2em);
  margin: auto;
  padding: 1em;
  background-color: var(--fade);
  border-radius: 0.2em;
  border: 2px solid black;
}

#bingo-add-window h3 { margin: 0; }

.bingo-board-add { margin-top: 15% !important; }
.bingo-board-search { margin-top: 5% !important; }

.bingo-track, .bingo-artist {
  object-fit: cover;
}


.bingo-add-error { 
  color: var(--error); 
  margin: 0.2em 0;
}

#bingo-add-window {
  justify-content: space-between; 
  margin-bottom: 1em;
}


.edit-board-action {
  margin-right: 2em;
}

.edit-board-action img {
  width: 1em;
  height: 1em;
  object-fit: cover;
  margin-right: 0.5em;
}

.edit-board-action p {
  font-weight: bold;
}

.close-edit-bingo-pick {
  justify-content: space-between;
}

.close-edit-bingo-pick h3 { margin: 0.2em 0; }


.swap {
  position: relative;
}

.bingo-track-swap {
  background-color: rgba(0,0,0,0.7);
  padding: 0.2em;
  border-radius: 0.2em;;
}

.bingo-track-swap p { margin: 0}

.bingo-pick-bg p { 
  margin: 0.14em; 
  font-size: 0.7em; 
  color: var(--white);
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  overflow: hidden;    
  width: 100%;
}

.bingo-rank { text-align: center;}

.bingo-hold {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url('../images/close.png');    
  background-size: contain;
  background-repeat: round;
}

.full-width { width: 100%; }

.bingo-pick-bg {
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0.2em;
  border-radius: 0.2em;  
  max-width: 100%;
}

#bingo-search-results {
  width: calc(100% - 1.2em - 2px);
  padding: 0.2em 0.6em;
  background-color: var(--fade);
  border-radius: 0.4em;
  border: 1px solid var(--black);
  overflow-y: scroll;
  max-height: 55vh;
  margin-bottom: 1em;
}

#bingo-update-holder {
  font-size: 0.6em;
  width: 25em;
}

#bingo-update-content { justify-content: space-around; }

#close-update {
  position: absolute;
  left: 96%;
  top: 8%;
  width: 1em;
}

@media only screen and (max-width: 600px) {
  #bingo-update-holder { display: none;}

}

@media only screen and (min-width: 600px) {
  .bingo-board-add {
    width: calc(100% - 200px - 2em);
    margin: 0 100px;
  }

}

@media only screen and (min-width: 800px) {
  .bingo-board-add {
    width: calc(100% - 400px - 2em);
    margin: 0 200px;
  }

}

@media only screen and (min-width: 900px) {

  .bingo-board-add {
    width: calc(100% - 500px - 2em);
    margin: 0 250px;
  }

}