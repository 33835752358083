

#top-tracks { width: 100%; }


.top-track {
  margin: 0;
  padding: 0;
  margin-bottom: 1.5em;
}

ul {
  padding: 0;
}


.ranking-title {
  max-width:calc(100% - 2.5em);
  align-items: flex-start;
}


.rank-title {
  margin: 0;
}

.artist {
  margin: 0.2em 0 0 0;
  width: calc(100% - 1em);
}

.track-info {
  width: calc(100% - 4.5em);
  justify-content: space-between;
  margin-left: 1em;
}

.card-actions {
  flex-wrap: wrap;
  margin-top: 0.3em;
}

.card-actions > * {
  padding: 0.2em 0;
}

.preview-section {
  margin-right: 1em;
}

.preview-section p {
  margin: 0 0 0 0.2em;
  font-style: italic;
  font-weight: 300;
}

.push-right {
  margin-left: 1.2em;
}

.preview-image {
  width: 1em;
  height: 1em;
  object-fit: contain;
}

.top-artist { margin-bottom: 1.5em; }

.artist-info {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 0.2em;
}

.artist-title {
  width: calc(100% - 6em);
  margin: 0.2em 0 0 0;
}


.eligibility {
  width: 1em;
  height: 1em;
  margin-left: 0.5em;
  object-fit: contain;
}


.album-content {
  width: 100%;
  margin-left: 1em;
}

@media only screen and (min-width: 800px) {
  #top-tracks, .pastTopTracks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .top-track {
    margin: 1em 1em 1em 0;
    width: 30em;
  }

  .top-artist {
    margin: 2em 4em 0 0;
    width: 22em;
  }

}
