

.listen-spotify {
  margin-right: 0.5em;
  cursor: pointer;
}

.listen-spotify-search {
  margin-top: 0.3em;
}

.listen-spotify img {
  width: 1.2em;
  height: 1.2em;
  object-fit: contain;
  margin-right: 0.3em;
}

.listen-spotify p {
  margin: 0;
}


.play-on-spotify {
  display: block;
}

.view-on-spotify {
  display: none;
}

@media only screen and (max-width: 1000px) {

  .play-on-spotify {
    display: none;
  }

  .view-on-spotify {
    display: block;
  }

}