
#countdown-container {
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 0;
  top: 25%;
}

#logo {
  height: 20vh;
}

#countdown {
  font-size: 3em;
  background-color: var(--background);
  margin: 0.5em;
  display: inline;
  text-align: center;
  max-width: 80vw;
}

#flowers {
  width: 70%;
  justify-content: space-around;
}

.flower {
  height: 8em;
  width: 8em;
  object-fit: contain;
}


@media only screen and (max-width: 800px) {
  #countdown-container {
    font-size: 0.7em;
  }

  .flower {
    height: 6em;
    width: 6em;
    object-fit: contain;
  }

  #logo {
    height: 15vh;
  }
  
}



@media only screen and (max-width: 500px) {
  #countdown-container {
    font-size: 0.5em;
  }

  .flower {
    height: 5em;
    width: 5em;
    object-fit: contain;
  }

  #logo {
    height: 12vh;
  }
  
}

