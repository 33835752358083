
.player { 
  display: flex;
  flex-direction: row;
  padding-top: 1em; 
}

.playerInfo { width: -webkit-fill-available; }

.player-name {
  margin: 0;
  font-size: 1.4em;
}

.player-image {
  padding: 0 1em;
}

.player-score {
  margin: 0;
  font-size: 1em;
}

.board-rank {
  font-size: 2em;
}


#now-playing {
  position: fixed;
  bottom: 1em;
  left: 0;
  z-index: 100;
  height: 5em;
  margin-left: 50px;
  width: calc(100vw - 100px);
  justify-content: space-around;
  border: 1px solid black;
  background-color: var(--fade);
}

.np-track-info p { margin: 0.4em 0 0 0.5em }

#highlight-img {
  width: 4em;
  height: 4em;
  object-fit: contain;
  padding-left: 1em;
}

#highlight-info p {
  margin: 0.2em;
  padding-left: 0.4em;
}


#player-pick { margin-top: 1em; }

.playerPickInfo, .playerPicksInfo {
  width: 80%;
  margin-left: 1em;
}

.pick-placing { margin: 0.2em 0; }

.pick-attr { padding: 0 0.3em; }


.desktop-section {
  width: 33%;
  overflow-x: hidden;
}

#top-5-mobile-view .live-comp {
  height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.top-track {
  min-width: 300px;
}


#update-score { 
  margin-top: 1em; 
  display: flex;
  justify-content: center;
}

.update-ranking {
  font-weight: 700;
  color: var(--alt);
  height: 1em;
  margin: 0 0 0 0.2em;
}

#update-score-formula { margin-left: 0.3em; }
#update-score span {
  font-weight: bold;
}

#player-update-p { margin-left: 1em; }
#player-update-p img { margin-right: 0.5em; }

.update-rank { font-size: 2.2em; }

#player-update-track img { margin: 1em; }

.player-update-track-info p {
  font-size: 1.2em;
  margin: 0.5em 0
}

#player-update {
  position: fixed;
  bottom: 1em;
  left: 0;
  border: 1px solid black;
  background-color: var(--fade);
  width: 70vw;
  margin-left: 14vw;
  z-index: 1000;
  font-size: 0.9em;
}

#player-update-content {
  padding: 1em;
}

#player-update-result {
  text-align: center;
}

#top-5-desktop-view { font-size: 0.8em; }

@media only screen and (min-width: 700px) {

  #top-5-desktop-view {
    position: absolute;
    top: 5em;
    left: 2em;
    width: 100%;
    height: calc(100% - 9em);
  }

} 

@media only screen and (max-width: 1000px) {
  .desktop-section { width: 100%; }

  #player-update { font-size: 0.75em; }

} 

@media only screen and (max-width: 600px) {

  #player-update {
    width: calc(100vw - 100px);
    margin-left: 50px;
    z-index: 1000;
  }

} 